.Markets_Container {
    margin-top: 200px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.Markets_Title {
    width: 100%;
    background: white;
    border-top: 8px solid #4287f5;
    border-bottom: 5px solid #4287f5;
    font-size: 2em;

}

.Markets_titles {
    color: #4287f5;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 15px;
}