.Carousel_container {
    height: 500px;
    margin-top: 60px;
    width: 100%;
    background-color: #34495E; 
}

.carousel {
	margin: 0;
    width: 80%;
    min-height: 300px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    top: 150px;
}

.image-slide {
    min-height: 300px;
    max-height: 400px;
	width: 100%;
	transition: background-image .3s ease-in-out;
}

.slide-overlay {
    background: rgba(0, 0, 0, 0.3);
    width: 100%;
    height: 80px;
    position: absolute;
    bottom: 0px;
    left: 0px;
    z-index: 2;
}

.slide-arrow {
	color:#fff;
	cursor: pointer;
    font-size: 2.5rem;
}

.slide-arrow.right {
    right: 0px;
    position: absolute;
    bottom: 5px;
    z-index:3;
}

.slide-arrow.left {
    left: 0px;
    position: absolute;
    bottom: 5px;
    z-index:3;
}

.image-title {
    font-size: 3rem;
    position: absolute;
    top: 5px;
    left: 50px;
    color: white;
    text-decoration: none;
}


@media only screen and (max-width: 600px) {
    .image-title {
        font-size: 2em;
        top: 20px;
        left: 40px;
    }

    .slide-arrow {
        font-size: 1.8rem;
    }
    .slide-arrow.right {
        bottom: 15px;
    }
    
    .slide-arrow.left {
        bottom: 15px;
    }
    
  }