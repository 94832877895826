.Payment_Container {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    min-height: 200px;
    margin-top: 50px;
    border-top: 8px solid #4287f5;
    background: white;
}

.Paypal_logo {
    max-height: 50px;
    padding: 5px;
}

.Square_logo {
    max-height: 80px;
    padding: 5px;
}

.Payment_title {
    color: #4287f5;
    font-size: 2.2em;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 15px;
}

.Payment_types {
    width: 100%;
    border-top: 5px solid #4287f5;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

.Payment_content {
    max-width: 180px;
    padding: 5px;
}

@media only screen and (max-width: 600px) {
    .Paypal_logo {
        max-height: 30px;
    }
    
    .Square_logo {
        max-height: 60px;
    }

}

@media only screen and (max-width: 480px) {

    .Paypal_logo {
        max-height: 20px;
    }
    
    .Square_logo {
        max-height: 40px;
    }

    .Payment_content {
    font-size: 14px;
    }

}