.Header_wrapper {
    background-color: white;
    position: fixed;
    z-index: 4;
    height: 170px;
    top: 0;
    left: 0;
    width: 100%;
}

.Header_container {
    width: 100%;
    height: 100px;
    position: fixed;
    top: 25px;
    left: 0;
    z-index: 5;
    background: #4287f5;
}

.Header_title {
    position: absolute;
    left: 225px;
    top: -5px;
    display: flex;
    flex-direction: column;
    height: 100px;
}

.Header_title_bottom {
    font-family: 'Arvo', serif;
    font-size: 1.5em;
}

.Header_title_top {
    font-family: 'Yanone Kaffeesatz', sans-serif;
    font-size: 2.75em;
    font-weight: 600;
    color: #fff;
}

.bigger {
    font-size: 2.5em;
}

.ESS_logo {
    height: 150px;
    position: absolute;
    left: 50px;
    top: -25px;
}

.White_triangle {
    position: absolute;
    right: 0px;
    width         : 0;
    height        : 0;
    border-left   : 130px solid transparent;
    border-right  : 0px solid transparent;  
    border-bottom : 135px solid #fff;
}

@media only screen and (max-width: 600px) {
    .ESS_logo {
        height: 100px;
        position: absolute;
        left: 20px;
        top: -10px;
    }

    .Header_container {
        width: 100%;
        height: 80px;
        position: fixed;
        top: 10px;
    }

    .Header_title_bottom {
        font-size: 1.1em;
    }

    .Header_title_top {
        font-size: 2.2em;
        font-weight: 600;
    }

    .Header_title {
        left: 140px;
        top: -5px;

    }
    
  }

  @media only screen and (max-width: 480px) {
    .Header_wrapper {
        height: 140px;
    }

    .White_triangle {
        display: none;
        }

        .ESS_logo {
            height: 80px;
            position: absolute;
            left: 20px;
            top: -10px;
        }
    
        .Header_container {
            width: 100%;
            height: 60px;
            position: fixed;
            top: 10px;
        }
    
        .Header_title_bottom {
            font-size: 1.1em;
        }
    
        .Header_title_top {
            font-size: 1.7em;
            font-weight: 600;
        }
    
        .Header_title {
            left: 120px;
            top: -5px;
    
        }
  }