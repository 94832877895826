.Contact_Container {
    margin-top: 200px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.Contact_Container a {
    text-decoration: none;
    color: #4287f5;
    
}

.Contact_Container a:hover {
    text-decoration: none;
    color: #4287f5;
}

.Contact_section {
    width: 30%;
    margin-top: 10px;
}

.Contact_Title {
    width: 100%;
    background: white;
    border-top: 8px solid #4287f5;
    border-bottom: 5px solid #4287f5;
    font-size: 2em;
}

.Contact_titles {
    color: #4287f5;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 15px;
}

.Map {
    width: 45%;
    height: 80%;
    display: block;
    margin-left: 5%;
    margin-top: 15px;
    border: 2px solid #4287f5;
}

.Map_wrapper {
    height: 400px;
    margin-top: 50px;
    background: white;
    border-top: 8px solid #4287f5;
    padding-top: 10px;
}

.Info_wrapper {
    height: 300px;
    margin-top: 50px;
    background: white;
    border-top: 8px solid #4287f5;
    display: flex;
    flex-direction: row;
    justify-items: flex-start;
    align-content: center;
}

.Phone_content {
    display: block;
    font-size: 1.5em;
    padding: 10px;
    margin-top: 10px;
}

.Map_content {
    display: block;
    text-align: left;
    padding: 20px;
    font-size: 1.5em;
    width: 45%;
}

.Map_Info_wrapper {
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

@media only screen and (max-width: 600px) {
    .Contact_Container {
        font-size: 12px; 
    }

    .Info_wrapper {
        height: 400px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .Contact_section {
        width: 80%;
        margin-top: 10px;
    }

    .Map_Info_wrapper {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .Map_content {
        display: block;
        text-align: left;
        padding: 20px;
        font-size: 1.5em;
        width: 90%;
    }

    .Map_wrapper {
        height: 500px;
    }

    .Map {
        width: 90%;
        height: 40%;
 
    }
  }

  @media only screen and (max-width: 480px) {
 
    
  }