.About_Container {
    margin-top: 200px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.About_Picture_Container {
background-color: #34495E; 
width: 130%;
height: 420px;
margin-top: 50px;
margin-left: -15%;
}

.Building_Pic {
    width: 550px;
    margin-right: 5%;
    float: right;
    margin-top: 20px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4), 0 6px 20px 0 rgba(0, 0, 0, 0.4);
}

.About_Header_Container {
    float: left;
    color: white;
    margin-left: 5%;
}

.About_Title {
    width: 100%;
    background: white;
    border-top: 8px solid #4287f5;
    border-bottom: 5px solid #4287f5;
    font-size: 2em;

}

.About_titles {
    color: #4287f5;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 15px;
}