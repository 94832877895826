.Products_Container {
    margin-top: 200px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.Products_Title {
    width: 100%;
    background: white;
    border-top: 8px solid #4287f5;
    border-bottom: 5px solid #4287f5;
    font-size: 2em;

}

.Products_titles {
    color: #4287f5;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 15px;
}

.Distribution_brands {
    width: 100%;
    background: white;
    border-top: 8px solid #4287f5;
    margin-top: 50px;
    min-height: 200px;
}

.Products_custom {
    width: 100%;
    background: white;
    border-top: 8px solid #4287f5;
    margin-top: 50px; 
    min-height: 200px;
    font-size: 1.3em;
}

.Distribution_flex {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: top;
    padding: 20px;
}

.Products_custom_flex {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: top;
    padding: 20px;
}