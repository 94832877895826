.Product_slider_container {
    height: 325px;
    background-color: white;
    margin-top: 50px;
    border-top: 8px solid #4287f5;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.Product_item_container {
    display: block;
    height: 250px;
    width: 200px;
    padding: 0;
    margin: 0;
    text-align: center;
    border-top: 5px solid #4287f5;
}

.Product_title {
    text-align: center;
    display: block;
}

.Product_img {
    height: 180px;
    margin-left: auto;
    margin-right: auto;
}

.Product_slider_title {
    color: #4287f5;
    font-size: 2.2em;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 15px;
}

