.Social_Container {
    width: 45%;
    height: 200px;
    margin-top: 50px;
    border-top: 8px solid #4287f5;
    background: white;
}

.Social_title {
    color: #4287f5;
    font-size: 2.2em;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 15px;
}

.Payment_types {
    width: 100%;
    height: 150px;
    border-top: 5px solid #4287f5;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

.FBLOGO {
    height: 80px;
}



@media only screen and (max-width: 600px) {
    .FBLOGO {
        height: 60px;
    }
    
  }

  @media only screen and (max-width: 480px) {
    .FBLOGO {
        height: 40px;
    }
    
  }