.Footer_wrapper {
    position: relative;
    background: #4287f5;
}

.Footer_container {
    width: 100%;
    height: 200px;
    position: absolute;
    overflow: hidden;
    bottom: 0;
    background: #4287f5;
    color: #fff;
    clear: both;
    margin-bottom: -250px;
}

.Footer_container a {
    text-decoration: none;
    color: #fff;
}

.Footer_container a:hover {
    text-decoration: none;
    color: #ddd;
}

.Footer_content {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background: #4287f5;
    color: #fff;
    display: flex;
    flex-direction: row; 
    justify-content: space-between;
    align-items: flex-start;
    clear: both;
}


.Address_container {
    display: block;
    padding: 10px;
    width: 150px;
}

.Phone_container {
    display: block;
    padding: 10px;
    width: 150px;
}

.Fax_container {
    display: block;
    padding: 10px;
    width: 150px;
}

.Copyright_container {
    display: block;
    padding: 10px;
    width: 150px;
}

.Slogan_container {
    display: block;
    padding: 10px;
    width: 150px;
}

.Card_container {
    display: block;
    padding: 10px;
    width: 150px;
}

.ESSCARD {
    width: 150px;
}

@media only screen and (max-width: 600px) {
    .Footer_content {
        width: 95%;
    }
    
  }

  @media only screen and (max-width: 480px) {
    .Footer_content {
        width: 100%;
        font-size: 13px;
    }


    .Footer_container {
        height: 225px;
    }

    .ESSCARD {
        width: 130px;
    }
    
  }