/* Position and sizing of burger button */
.bm-burger-button {
    position: fixed;
    width: 36px;
    height: 30px;
    right: 60px;
    top: 30px;
  }
   
  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: #fff;
  }
   
  /* Color/shape of burger icon bars on hover*/
  .bm-burger-bars-hover {
    background: #fff;
  }
   
  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 24px;
    width: 24px;
  }
   
  /* Color/shape of close button cross */
  .bm-cross {
    background: #fff;
  }
   
  /*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
  .bm-menu-wrap {
    position: fixed;
    height: 100%;
    top: 0;
  }
   
  /* General sidebar styles */
  .bm-menu {
    background: #4287f5;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
  }
   
  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #4287f5;
  }
   
  /* Wrapper for item list */
  .bm-item-list {
    color: #fff;
    padding: 0.8em;
    font-size: 1.2em;
  }

  .bm-item-list a{
    color: #000;
    padding: 0.8em;
    text-decoration: none;
    font-weight: 500;
  }

  .bm-item-list a:hover{
    color: #fff;
    padding: 0.8em;
    text-decoration: none;
  }
   
  /* Individual item */
  .bm-item {
    display: inline-block;
  }
   
  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 0;
  }

  @media only screen and (min-width: 1024px) {
    .bm-burger-button {
      display: none;
    }
  }

  @media only screen and (max-width: 480px) {
    .bm-burger-button {
      position: fixed;
      width: 36px;
      height: 30px;
      right: 40px;
      top: 20px;
    }

  }