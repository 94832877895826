.NavBar_Container {
    position: fixed;
    right: 10%;
    top: -12px;
    color: white;
}

.NavBar_Container a{
    color: white;
    text-decoration: none;
    font-size: 20px;
}

.NavBar_Container ul{
    list-style: none;
}

.NavBar_Container li{
display: inline-block;
background-color: #4287f5;
height: 22px;
width: 80px;
text-align: center;
border-right: 2px solid white;
border-radius: 3px;
}

@media only screen and (max-width: 1024px) {
    .NavBar_Container {
      display: none;
    }
  }